import { useEffect } from 'react'

import { useFeatureSwitch } from '@marketplace-web/shared/feature-switches'
import { getSellerBadgesList } from 'data/api'
import useAbTest from 'hooks/useAbTest'
import useFetch from 'hooks/useFetch'
import useSession from 'hooks/useSession'
import { UserDto } from 'types/dtos'

type Props = {
  user: UserDto | undefined
}

const useFetchSellerBadgesList = ({ user }: Props) => {
  const { data: sellerBadgesList, fetch: fetchSellerBadgesList } = useFetch(getSellerBadgesList)
  const isFastReplierBadgeEnabled = useFeatureSwitch('web_fast_replier_badge')
  const fastReplierBadgeAbTest = useAbTest({ abTestName: 'fast_replier_badge' })
  const { id: currentUserId, business: isCurrentUserBusiness } = useSession().user || {}
  const { id: userId, business } = user || {}

  const isViewingSelf = currentUserId === userId
  const bothUsersNotBusiness = !isCurrentUserBusiness && !business

  const shouldFetchSellerBadgesList =
    isFastReplierBadgeEnabled &&
    fastReplierBadgeAbTest?.variant === 'on' &&
    !isViewingSelf &&
    bothUsersNotBusiness

  useEffect(() => {
    if (!userId) return

    if (shouldFetchSellerBadgesList) fetchSellerBadgesList(userId)
  }, [fetchSellerBadgesList, isViewingSelf, shouldFetchSellerBadgesList, user, userId])

  return {
    sellerBadgesList: sellerBadgesList?.badges,
  }
}

export default useFetchSellerBadgesList
