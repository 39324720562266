'use client'

import { Component, ReactComponentElement, MouseEvent, ReactNode } from 'react'
import { Cell, Text, Checkbox, Radio, Badge } from '@vinted/web-ui'
import { isFunction } from 'lodash'

import List from 'components/List'
import { SelectableListItem } from 'types/components'
import { CatalogBadgeModel } from 'types/models'
import { WEB_CATALOG_ROOT_ALL_CODE } from 'constants/catalog'

type ListItemClickCallback<T, V> = (
  item: SelectableListItem<T, V>,
  event: MouseEvent,
  index: number,
) => void

export type RenderItemProps<T, V extends string | number = string | number> = {
  itemElementProps: ComponentProps<typeof Cell>
  isSelected: boolean
  item: SelectableListItem<T, V>
  button: ReactComponentElement<typeof Checkbox> | ReactComponentElement<typeof Radio>
}

type Props<T, V extends string | number = string | number> = {
  name: string
  items: Array<SelectableListItem<T, V>>
  selected: Array<V>
  onItemClick?: ListItemClickCallback<T, V>
  isMultiSelect: boolean
  renderItem?: (props: RenderItemProps<T, V>, index: number) => ReactNode
  itemButtonTestId?: string
  disableButtonClicks?: boolean
}

const isNotRootCatalog = (data: any): data is { code: string } => {
  return data && data.code !== WEB_CATALOG_ROOT_ALL_CODE
}

const hasBadgeProperty = (data: any): data is { badge: CatalogBadgeModel } => {
  return data && typeof data.badge !== 'undefined'
}

const renderBadge = (badge: CatalogBadgeModel): ReactNode => {
  if (!badge) return null

  return <Badge theme={badge.theme || 'primary'} content={badge.title} />
}

class SelectableItemList<T, V extends string | number = string | number> extends Component<
  Props<T, V>
> {
  static defaultProps = {
    items: [],
    selected: [],
    isMultiSelect: false,
    disableButtonClicks: false,
  }

  onItemClick = (item: SelectableListItem<T, V>, index: number) => (event: MouseEvent) => {
    const { disableButtonClicks, onItemClick } = this.props

    if (!(event.target instanceof HTMLAnchorElement) && !disableButtonClicks) {
      event.preventDefault()
    }

    if (isFunction(onItemClick)) onItemClick(item, event, index)
  }

  renderButton(item: SelectableListItem<T, V>) {
    const { name, selected, isMultiSelect, disableButtonClicks, itemButtonTestId } = this.props
    const { value, data } = item
    const componentProps = {
      name: isMultiSelect ? `${name}[]` : name,
      value,
      checked: selected.includes(value),
      aria: {
        'aria-labelledby': `${name}-list-item-${item.id}`,
      },
      testId: itemButtonTestId && `${itemButtonTestId}-${item.id}`,
      onChange: () => undefined,
      onClick: (event: MouseEvent<HTMLInputElement>) => {
        if (disableButtonClicks) return

        event.stopPropagation()
      },
    }

    const badgeElement =
      hasBadgeProperty(data) && isNotRootCatalog(data) ? renderBadge(data.badge) : null
    let element = <Radio {...componentProps} />

    if (isMultiSelect) {
      element = <Checkbox {...componentProps} />
    }

    if (!disableButtonClicks) return element

    return (
      <Cell styling={Cell.Styling.Tight} prefix={badgeElement}>
        <div className="u-no-pointer-events">{element}</div>
      </Cell>
    )
  }

  renderItem(item: SelectableListItem<T, V>, index: number) {
    const { name, selected, renderItem } = this.props
    const onClick = this.onItemClick.call(this, item, index)
    const button = this.renderButton.call(this, item)

    const itemElementProps = {
      id: `${name}-list-item-${item.id}`,
      key: item.id,
      type: Cell.Type.Navigating,
      body: <Text text={item.title} type={Text.Type.Title} as="span" />,
      onClick,
      suffix: button,
    }

    if (isFunction(renderItem)) {
      return renderItem(
        {
          itemElementProps,
          isSelected: selected.includes(item.value),
          item,
          button,
        },
        index,
      )
    }

    return <Cell {...itemElementProps} />
  }

  render() {
    const { items } = this.props

    return <List>{items.map(this.renderItem.bind(this))}</List>
  }
}

export default SelectableItemList
