import { ReactNode } from 'react'
import { Cell, Icon, Spacer, Text } from '@vinted/web-ui'

type ProfileInfoBlockData = {
  iconName: ComponentProps<typeof Icon>['name']
  color?: ComponentProps<typeof Icon>['color']
  text: ReactNode
  enableHtml?: boolean
  theme?: ComponentProps<typeof Text>['theme']
}

type Props = {
  children?: ReactNode
  useFlexbox?: boolean
  data?: ProfileInfoBlockData
}

const ProfileInfoBlock = ({ children, useFlexbox = false, data }: Props) => {
  const renderDefaultContent = () => {
    if (!data) return null

    const { iconName, color, text, enableHtml, theme } = data

    return (
      <>
        <div className="u-flexbox u-align-items-center">
          <Icon name={iconName} color={color} />
        </div>
        <div>
          <Spacer orientation={Spacer.Orientation.Vertical} />
        </div>
        <div>
          <Text as="span" text={text} html={enableHtml} theme={theme} />
        </div>
      </>
    )
  }

  const content = children || renderDefaultContent()

  if (!useFlexbox) return content

  return (
    <Cell>
      <div className="u-flexbox">{content}</div>
    </Cell>
  )
}

export default ProfileInfoBlock
