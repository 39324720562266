'use client'

import { useState } from 'react'
import { Button, Cell, Icon, Spacer } from '@vinted/web-ui'
import { ClimateImpact24, InfoCircle24 } from '@vinted/monochrome-icons'

import HorizontalScrollArea from 'components/HorizontalScrollArea'
import SeparatedList from 'components/SeparatedList'
import { ControlScrollType } from 'components/HorizontalScrollArea/HorizontalScrollArea'

import useTranslate from 'hooks/useTranslate'

import useGetSustainabilityImpact from '../hooks/useGetSustainabilityImpact'
import SustainabilityImpactValuePropositionModal from './SustainabilityImpactValuePropositionModal'
import SustainabilityImpactCard from './SustainabilityImpactCard'

const SustainabilityImpact = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { sustainabilityImpact, isSustainabilityImpactLoading } = useGetSustainabilityImpact()
  const translate = useTranslate('profile.seller_insights.sustainability_impact')

  const handleOpenModal = () => setIsModalOpen(true)

  const handleCloseModal = () => setIsModalOpen(false)

  const renderHeader = () => (
    <Cell
      prefix={<Icon name={ClimateImpact24} color={Icon.Color.GreyscaleLevel3} />}
      title={translate('title')}
      suffix={
        <Button
          inline
          iconName={InfoCircle24}
          size={Button.Size.Small}
          styling={Button.Styling.Flat}
          iconColor={Icon.Color.GreyscaleLevel3}
          testId="sustainability-impact-info"
          onClick={handleOpenModal}
        />
      }
      styling={Cell.Styling.Tight}
    />
  )

  if (!sustainabilityImpact || isSustainabilityImpactLoading) return null

  return (
    <>
      <Spacer size={Spacer.Size.Medium} />
      {renderHeader()}
      <Spacer size={Spacer.Size.Medium} />
      <HorizontalScrollArea controlsScrollType={ControlScrollType.Partial}>
        <SeparatedList
          separator={<Spacer orientation={Spacer.Orientation.Vertical} size={Spacer.Size.Large} />}
        >
          {sustainabilityImpact.map((fact, index) => (
            <SustainabilityImpactCard
              key={`sustainability-impact-card-${fact.title}`}
              {...fact}
              index={index}
            />
          ))}
        </SeparatedList>
      </HorizontalScrollArea>
      <SustainabilityImpactValuePropositionModal
        show={isModalOpen}
        onCloseOrConfirm={handleCloseModal}
      />
    </>
  )
}

export default SustainabilityImpact
