'use client'

import { Dialog } from '@vinted/web-ui'

import useTranslate from 'hooks/useTranslate'

type Props = {
  isOpen: boolean
  onCancel: () => void
  onContinue: () => void
}

const LeavingVintedModal = ({ isOpen, onCancel, onContinue }: Props) => {
  const translate = useTranslate('leaving_vinted_modal')

  return (
    <Dialog
      show={isOpen}
      testId="leaving-vinted-modal"
      title={translate('title')}
      body={translate('body')}
      actions={[
        {
          callback: onContinue,
          style: Dialog.ActionStyling.Filled,
          text: translate('actions.continue'),
          testId: 'leaving-vinted-modal--continue-button',
        },
        {
          callback: onCancel,
          style: Dialog.ActionStyling.Flat,
          text: translate('actions.cancel'),
          testId: 'leaving-vinted-modal--cancel-button',
        },
      ]}
    />
  )
}

export default LeavingVintedModal
