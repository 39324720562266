'use client'

import { isEmpty, noop } from 'lodash'

import { List, Spacer, Text } from '@vinted/web-ui'
import {
  CheckCircle16,
  Clock16,
  Document16,
  Envelope16,
  Flag16,
  LocationPin16,
  Member16,
  Rss16,
  Smartphone16,
} from '@vinted/monochrome-icons'

import { useFeatureSwitch } from '@marketplace-web/shared/feature-switches'
import { UserDto } from 'types/dtos'
import { MEMBER_TEMPLATES_URL } from 'constants/routes'
import { VERIFICATION_LIST } from 'constants/index'

import useTranslate from 'hooks/useTranslate'

import { FormattedDateMessage } from 'components/@support'
import SeparatedList from 'components/SeparatedList'

import UserInfoBlock from './UserInfoBlock'
import FollowInfo from '../FollowInfo'

type Props = {
  user: UserDto
  showSupportFunctionality: boolean
  canSendTemplates: boolean
  onTogglePoliciesModal?: () => void
  viewingSelf: boolean
}

const UserInfo = ({
  user,
  showSupportFunctionality,
  canSendTemplates,
  viewingSelf,
  onTogglePoliciesModal = noop,
}: Props) => {
  const translate = useTranslate('user')

  const {
    email,
    last_loged_on_ts,
    created_at,
    followers_count,
    following_count,
    msg_template_count,
    expose_location,
    city,
    country_title_local,
    id,
    business: isBusinessAccount,
    business_account,
  } = user
  const memberSinceDate = new Date(created_at)
  const isUKCompanyNumberHidden = useFeatureSwitch('hide_uk_sole_traders_company_number')

  const renderLocationContent = () => {
    const hideBusinessLocation =
      isBusinessAccount && business_account?.entity_type === 'sole_trader'
    const businessAddress = isBusinessAccount ? business_account?.address : null
    const locationDescription =
      expose_location && city ? [city, country_title_local].join(', ') : country_title_local

    if (locationDescription && !isBusinessAccount) {
      return locationDescription
    }

    if (businessAddress && hideBusinessLocation) {
      const businessLocationDescription =
        expose_location && city
          ? [businessAddress.city, businessAddress.country_title].join(', ')
          : businessAddress.country_title

      return businessLocationDescription
    }

    if (businessAddress && !hideBusinessLocation) {
      return (
        <>
          {businessAddress?.line1 && <div>{businessAddress.line1}</div>}
          {businessAddress?.line2 && <div>{businessAddress.line2}</div>}
          {businessAddress?.postal_code && businessAddress?.city && (
            <div>
              {businessAddress.postal_code} {businessAddress.city}
            </div>
          )}
          {businessAddress?.country_title && <div>{businessAddress.country_title}</div>}
        </>
      )
    }

    return null
  }

  const renderLocationBlock = () => {
    if (!renderLocationContent()) {
      return null
    }

    return (
      <List.Item>
        <UserInfoBlock iconName={LocationPin16} testId="profile-location-info">
          {renderLocationContent()}
        </UserInfoBlock>
      </List.Item>
    )
  }

  function renderAbout() {
    const hideBusinessEmail = isBusinessAccount && business_account?.personal_email
    const hideBusinessPhoneNumber = isBusinessAccount && business_account?.personal_phone_number
    const businessEmail = isBusinessAccount && business_account?.email
    const businessPhone = isBusinessAccount && business_account?.phone_number

    return (
      <div>
        <Text as="h4" type={Text.Type.Caption} text={translate('about.title')} />
        <Spacer size={Spacer.Size.Small} />
        <List size={List.Size.Narrow}>
          {renderLocationBlock()}
          {businessEmail && (
            <List.Item>
              <UserInfoBlock iconName={Envelope16} testId="profile-business-email">
                {hideBusinessEmail ? (
                  <span>{businessEmail}</span>
                ) : (
                  <a href={`mailto:${businessEmail}`}>{businessEmail}</a>
                )}
              </UserInfoBlock>
            </List.Item>
          )}
          {businessPhone && (
            <List.Item>
              <UserInfoBlock iconName={Smartphone16} testId="profile-business-phone">
                {hideBusinessPhoneNumber ? (
                  <span>{businessPhone}</span>
                ) : (
                  <a href={`tel:${businessPhone}`}>{businessPhone}</a>
                )}
              </UserInfoBlock>
            </List.Item>
          )}
          {last_loged_on_ts && (
            <List.Item>
              <UserInfoBlock iconName={Clock16}>
                <FormattedDateMessage
                  id="user.last_login"
                  date={new Date(last_loged_on_ts)}
                  relativeDate
                />
              </UserInfoBlock>
            </List.Item>
          )}
          <List.Item>
            <UserInfoBlock iconName={Rss16}>
              <FollowInfo
                followersCount={followers_count}
                followingCount={following_count}
                userId={id}
                viewingSelf={viewingSelf}
              />
            </UserInfoBlock>
          </List.Item>
          {showSupportFunctionality && (
            <List.Item>
              <UserInfoBlock iconName={Member16}>
                <FormattedDateMessage id="user.since" date={memberSinceDate} />
              </UserInfoBlock>
            </List.Item>
          )}
          {showSupportFunctionality && (
            <List.Item>
              <UserInfoBlock iconName={Envelope16}>{email}</UserInfoBlock>
            </List.Item>
          )}
          {canSendTemplates && (
            <List.Item>
              <UserInfoBlock iconName={Flag16}>
                <a href={MEMBER_TEMPLATES_URL(user.id)}>
                  {translate(
                    'admin.template_count',
                    { count: msg_template_count },
                    { count: msg_template_count },
                  )}
                </a>
              </UserInfoBlock>
            </List.Item>
          )}
          {isBusinessAccount && (
            <List.Item>
              <UserInfoBlock iconName={Document16}>
                <button
                  type="button"
                  onClick={onTogglePoliciesModal}
                  data-testid="seller-policies-modal-toggle"
                >
                  <Text clickable text={translate('seller_policies.title')} as="span" />
                </button>
              </UserInfoBlock>
            </List.Item>
          )}
        </List>
      </div>
    )
  }

  function renderVerifications() {
    const filteredVerifications = VERIFICATION_LIST.filter(item => user.verification[item]?.valid)

    if (isEmpty(filteredVerifications) && !isBusinessAccount) return null

    const businessLegalCode = isBusinessAccount && business_account?.legal_code
    const businessRegistrarName = isBusinessAccount && business_account?.registrar_name
    const selfCertified = isBusinessAccount && business_account?.verified_identity

    const hideBusinessLegalCode =
      isUKCompanyNumberHidden &&
      user.business_account?.country === 'UK' &&
      user.business_account.entity_type === 'sole_trader'

    return (
      <div>
        <Text as="h4" type={Text.Type.Caption} text={translate('verification.title')} />
        <Spacer size={Spacer.Size.Small} />
        <List size={List.Size.Narrow}>
          {filteredVerifications.map(verification => (
            <List.Item key={verification}>
              <UserInfoBlock iconName={CheckCircle16}>
                {translate(`verification.${verification}`)}
              </UserInfoBlock>
            </List.Item>
          ))}
          {isBusinessAccount && (
            <List.Item>
              <UserInfoBlock iconName={CheckCircle16} testId="business-verification-identity">
                {translate('verification.business_identity')}
              </UserInfoBlock>
            </List.Item>
          )}
          {businessLegalCode && !hideBusinessLegalCode && (
            <List.Item>
              <UserInfoBlock iconName={CheckCircle16} testId="business-verification-legal-code">
                {translate('verification.legal_code')} {businessLegalCode}
              </UserInfoBlock>
            </List.Item>
          )}
          {businessRegistrarName && (
            <List.Item>
              <UserInfoBlock iconName={CheckCircle16} testId="business-verification-registrar-name">
                {businessRegistrarName}
              </UserInfoBlock>
            </List.Item>
          )}
          {selfCertified && (
            <List.Item>
              <UserInfoBlock iconName={CheckCircle16} testId="business-verification-self-certified">
                {translate('verification.complies_with_eu_law')}
              </UserInfoBlock>
            </List.Item>
          )}
        </List>
      </div>
    )
  }

  return (
    <div className="u-flexbox">
      <SeparatedList
        separator={<Spacer orientation={Spacer.Orientation.Vertical} size={Spacer.Size.X4Large} />}
      >
        {renderAbout()}
        {renderVerifications()}
      </SeparatedList>
    </div>
  )
}

export default UserInfo
