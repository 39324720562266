'use client'

import TranslationControl from 'components/TranslationControl'
import { ClickableElement } from 'constants/tracking/clickable-elements'

import { useFeedbackContext } from '../../FeedbackProvider'

type Props = {
  feedbackId: number
}

const FeedbackTranslationButton = ({ feedbackId }: Props) => {
  const { getFeedbackItemStateById, initiateTranslation } = useFeedbackContext()

  const isTranslated = getFeedbackItemStateById(feedbackId)?.isTranslated

  const handleInitiateTranslation = () => {
    initiateTranslation(feedbackId, !isTranslated)
  }

  return (
    <TranslationControl
      isTranslated={isTranslated}
      autotranslate={false}
      onClick={handleInitiateTranslation}
      tracking={{
        target: ClickableElement.TranslateFeedback,
        targetDetails: feedbackId.toString(),
      }}
    />
  )
}

export default FeedbackTranslationButton
