'use client'

import { ReactNode } from 'react'
import { useInView } from 'react-intersection-observer'

type Props = {
  renderItems: () => ReactNode
  onPageEnd: () => void
  cursor?: string
}

const InfiniteScroll = ({ renderItems, onPageEnd, cursor }: Props) => {
  const { ref } = useInView({
    rootMargin: '0px 0px 600px 0px',
    onChange: inView => {
      if (!inView) return
      onPageEnd()
    },
  })

  return (
    <>
      {renderItems()}
      <div ref={ref} key={cursor} data-testid="infinite-scroll" />
    </>
  )
}

export default InfiniteScroll
