'use client'

import { Cell } from '@vinted/web-ui'

import Notification from 'components/Notification'
import useTranslate from 'hooks/useTranslate'

type Props = { handleClose: () => void }

const ItemUploadFeedbackNotification = ({ handleClose }: Props) => {
  const translate = useTranslate('item_upload.feedback')

  return (
    <Notification
      testId="item-upload--feedback-notification"
      onClose={handleClose}
      body={
        <div className="item-upload__feedback-notification">
          <Cell
            theme="transparent"
            styling={Cell.Styling.Tight}
            title={translate('finalized.title')}
            body={translate('finalized.body')}
          />
        </div>
      }
      suffix={<div />}
    />
  )
}

export default ItemUploadFeedbackNotification
