import { useEffect } from 'react'

import useFetch from 'hooks/useFetch'
import { getSellerFilters } from 'data/api'
import { transformGetSellerFiltersResponse } from 'data/api/transformers/response'

const useGetSellerFilters = () => {
  const {
    fetch: fetchSellerFilters,
    isLoading: isSellerFiltersLoading,
    transformedData: sellerFilters,
    error: sellerFiltersError,
  } = useFetch(getSellerFilters, transformGetSellerFiltersResponse)

  useEffect(() => {
    fetchSellerFilters()
  }, [fetchSellerFilters])

  return {
    sellerFilters,
    isSellerFiltersLoading,
    sellerFiltersError,
  }
}

export default useGetSellerFilters
