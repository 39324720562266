import { useEffect, useState } from 'react'

import { getCatalogs, getUserItemFacets } from 'data/api'
import { transformCatalogDtosWithCounts } from 'data/transformers/catalog'

import { CatalogModel } from 'types/models'

type Props = {
  userId: number
}

const useFetchCatalogs = ({ userId }: Props) => {
  const [catalogs, setCatalogs] = useState<Array<CatalogModel>>([])

  useEffect(() => {
    const fetchUserItemFacets = async () => {
      const [catalogsResponse, userItemFacetsResponse] = await Promise.all([
        getCatalogs(),
        getUserItemFacets({ userId }),
      ])

      if ('errors' in catalogsResponse || 'errors' in userItemFacetsResponse) {
        setCatalogs([])

        return
      }

      const availableCatalogs = transformCatalogDtosWithCounts(
        catalogsResponse.catalogs,
        userItemFacetsResponse.catalogs,
      )

      setCatalogs(availableCatalogs)
    }

    fetchUserItemFacets()
  }, [userId])

  return {
    catalogs,
  }
}

export default useFetchCatalogs
