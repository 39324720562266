'use client'

import { useEffect, useRef, useState } from 'react'
import { Cell, Icon, Spacer, Text } from '@vinted/web-ui'
import { Globe24 } from '@vinted/monochrome-icons'

import useTracking from 'hooks/useTracking'
import { FormattedMessage } from 'components/@support'
import { clickEvent, clickEventArgs } from '_libs/common/event-tracker/events'

export enum Status {
  Original = 'original',
  Translating = 'translating',
  Translated = 'translated',
}

type Props = {
  onClick: (isTranslated: boolean) => void | Promise<void>
  isTranslated?: boolean
  autotranslate: boolean
  tracking?: clickEventArgs
  renderButton?: (status: Status) => JSX.Element
  isNavigating?: boolean
  clickable?: boolean
}

const defaultRenderButton = (status: Status) => {
  const messageTheme = status === Status.Translating ? undefined : 'primary'
  const translateById = (id: string) => <FormattedMessage id={`translation.status.${id}`} />

  return (
    <div className="u-flexbox u-align-items-center">
      <Icon name={Globe24} color={Icon.Color.Primary} aria={{ 'aria-hidden': 'true' }} />
      <Spacer orientation={Spacer.Orientation.Vertical} size={Spacer.Size.Small} />
      <div>
        {status === Status.Translated && (
          <>
            <Text as="span" text={translateById('translated_prefix')} />
            <Spacer orientation={Spacer.Orientation.Vertical} size={Spacer.Size.Small} />
            <span>‧</span>
            <Spacer orientation={Spacer.Orientation.Vertical} size={Spacer.Size.Small} />
          </>
        )}
        <Text as="span" text={translateById(status)} theme={messageTheme} />
      </div>
    </div>
  )
}

const TranslationControl = ({
  onClick,
  isTranslated = false,
  autotranslate,
  tracking,
  renderButton = defaultRenderButton,
  isNavigating = true,
  clickable,
}: Props) => {
  const [isTracked, setIsTracked] = useState(false)
  const [status, setStatus] = useState(isTranslated ? Status.Translated : Status.Original)
  const { track } = useTracking()

  const toggleTranslation = useRef(() => Promise.resolve())
  toggleTranslation.current = async () => {
    if (status === Status.Translating) return

    const prevStatus = status
    setStatus(Status.Translating)

    await onClick(isTranslated)

    setStatus(prevStatus === Status.Original ? Status.Translated : Status.Original)
  }

  useEffect(() => {
    setStatus(isTranslated ? Status.Translated : Status.Original)
  }, [isTranslated])

  useEffect(() => {
    if (isTranslated) return
    if (!autotranslate) return

    toggleTranslation.current()
  }, [autotranslate, isTranslated])

  const trackButtonClick = () => {
    if (!tracking) return

    track(clickEvent(tracking))
  }

  const handleClick = () => {
    if (!isTracked) {
      trackButtonClick()
      setIsTracked(true)
    }

    toggleTranslation.current()
  }

  return (
    <Cell
      styling={Cell.Styling.Tight}
      onClick={handleClick}
      type={isNavigating ? Cell.Type.Navigating : undefined}
      role="button"
      clickable={clickable}
    >
      {renderButton(status)}
    </Cell>
  )
}

export default TranslationControl
