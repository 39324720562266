'use client'

import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Badge, Cell, Divider, Spacer, Tabs, Text } from '@vinted/web-ui'
import classNames from 'classnames'

import { useFeatureSwitch } from '@marketplace-web/shared/feature-switches'
import { FormattedMessage } from 'components/@support'
import { ShowAtBreakpoint } from 'components/Breakpoint'
import { BumpCheckout } from 'components/Bumps'
import InfoBanner from 'components/InfoBanner'
import SeparatedList from 'components/SeparatedList'
import TaxpayersSpecialVerificationSuccessModal from 'components/TaxpayersSpecialVerificationSuccessModal'
import BumpCheckoutProvider from 'components/Bumps/containers/BumpCheckoutProvider'
import useSellerBadgeAbTest from 'components/SellerBadge/useSellerBadgeAbTest'

import useBreakpoint from 'hooks/useBreakpoint'
import useLocation from 'hooks/useLocation'
import useTracking from 'hooks/useTracking'
import useAbTest from 'hooks/useAbTest'
import useTranslate from 'hooks/useTranslate'
import useSession from 'hooks/useSession'
import useExposee from 'hooks/useExposee'

import { ClickableElement } from 'constants/tracking/clickable-elements'
import { Screen } from 'constants/tracking/screens'
import {
  viewScreenEvent,
  viewUserEvent,
  clickEvent,
  abTestExposeEvent,
} from '_libs/common/event-tracker/events'
import { isValueInObject } from '_libs/utils/object'
import { toUrlQuery } from '_libs/utils/url'

import { getBanners } from 'state/banners/selectors'

import { TabItem } from 'types/components'
import { UserDto } from 'types/dtos'

import { BusinessAccountBanner, PoliciesModal } from './BusinessAccount'
import InfoHeader from './InfoHeader'
import ProfileBanners from './ProfileBanners'
import ProfileFeedback from './ProfileFeedback'
import ProfileAdminSection from './ProfileAdminSection'

import ProfileInfo from './ProfileInfo'
import PromosAfterItemUpload from './PromosAfterItemUpload'
import UserItems from './UserItems'
import SellerInsights from './SellerInsights'
import useUserItems from './hooks/useUserItems'
import useWardrobeItems from './hooks/useWardrobeItems'
import useFetchProfileUserInfo from './hooks/useFetchUserInfo'
import { EprBanner } from './Epr'
import useFetchCatalogs from './hooks/useFetchCatalogs'
import FeedbackProvider from './ProfileFeedback/FeedbackProvider'
import VasEntryPointsProvider from './ProfileBanners/ProfileOwnerBanners/VasEntryPointsProvider'
import useFetchSellerBadgesList from './hooks/useFetchSellerBadgesList'
import PromoAfterItemUploadProvider from './containers/PromoAfterItemUploadProvider'
import useGetSellerInsights from './SellerInsights/hooks/useGetSellerInsights'

enum TabType {
  Closet = 'closet',
  Feedback = 'feedback',
  About = 'about',
  Insights = 'insights',
}

enum TabClickableElements {
  closet = ClickableElement.ProfileTab,
  feedback = ClickableElement.FeedbackTab,
  about = ClickableElement.AboutTab,
  insights = ClickableElement.SellerInsightsTab,
}

type ActiveTabProps = {
  showActions: boolean
}

type Props = {
  user: UserDto
}

const isTabType = (tabType: unknown): tabType is TabType => isValueInObject(tabType, TabType)

const Profile = ({ user }: Props) => {
  const breakpoints = useBreakpoint()
  const { replaceHistoryState, relativeUrl, searchParams } = useLocation()
  const { track } = useTracking()
  const translate = useTranslate('common')

  const { user: currentUser } = useSession()

  const isNewWardrobeEndpointEnabled = useFeatureSwitch('wardrobe_items_endpoint_web')

  const useItems = isNewWardrobeEndpointEnabled ? useWardrobeItems : useUserItems

  const {
    items,
    itemsCount,
    totalItemsCount,
    isLoading,
    sortBy,
    catalogId,
    selectedSellerFilter,
    fetchMoreItems,
    updateFilters,
  } = useItems({ userId: user.id })
  const { userInfo } = useFetchProfileUserInfo({ userId: user.id })
  const { catalogs } = useFetchCatalogs({ userId: user.id })
  const { sellerBadgesList } = useFetchSellerBadgesList({ user })
  const { sellerInsights, isSellerInsightsLoading } = useGetSellerInsights()

  const defaultActiveTab = isTabType(searchParams.tab) ? searchParams.tab : TabType.Closet

  const banners = useSelector(getBanners)
  const exposee = useExposee()

  const isContentTranslationEnabled = useFeatureSwitch('content_translation_web')
  const isPortalMergeSourceEnabled = useFeatureSwitch('portal_merge_source')

  const holidayModeRedirectAbTest = useAbTest({ abTestName: 'holiday_mode_redirect' })
  useSellerBadgeAbTest({
    abTestName: 'fast_replier_badge',
    user,
  })

  useSellerBadgeAbTest({
    abTestName: 'rename_star_wardrobe_badge',
    user,
  })

  const [activeTabId, setActiveTabId] = useState(defaultActiveTab)
  const [isPoliciesModalOpen, setIsPoliciesModalOpen] = useState(!!searchParams.policy)
  const [isInitialFetchDone, setIsInitialFetchDone] = useState(false)

  const isViewingSelf = currentUser?.id === user.id
  const screenName = isViewingSelf ? Screen.CurrentUserProfile : Screen.UserProfile

  const handleChangeTab = useCallback(
    (tabId: TabType) => {
      setActiveTabId(tabId)

      replaceHistoryState(`${relativeUrl}?${toUrlQuery({ tab: tabId })}`)
    },
    [relativeUrl, replaceHistoryState],
  )

  useEffect(() => {
    if (holidayModeRedirectAbTest?.variant === 'on' && searchParams.on_holiday) {
      track(abTestExposeEvent({ ...exposee, ...holidayModeRedirectAbTest }))
    }
  }, [exposee, holidayModeRedirectAbTest, searchParams.on_holiday, track])

  useEffect(() => {
    if (isInitialFetchDone) return

    setIsInitialFetchDone(true)
    fetchMoreItems()
  }, [isInitialFetchDone, fetchMoreItems])

  useEffect(() => {
    const refererPath = document.referrer ? new URL(document.referrer).pathname : 'unknown'

    track(viewUserEvent({ path: refererPath, user_id: user.id }))
  }, [track, user.id])

  useEffect(() => {
    track(
      viewScreenEvent({
        screen: screenName,
      }),
    )
  }, [screenName, track])

  useEffect(() => {
    if ([TabType.Closet, TabType.Feedback, TabType.Insights].includes(activeTabId)) return
    if (breakpoints.phones && activeTabId === TabType.About) return

    handleChangeTab(TabType.Closet)
  }, [activeTabId, breakpoints.phones, handleChangeTab])

  const onTabChange = (tab: TabItem) => {
    // TODO: Remove casting
    handleChangeTab(tab.id as TabType)

    const screen = isViewingSelf ? Screen.CurrentUserProfile : Screen.UserProfile
    track(clickEvent({ screen, target: TabClickableElements[tab.id] }))
  }

  const changeTab = (tabId: TabType) => () => {
    handleChangeTab(tabId)
  }

  const togglePoliciesModal = () => {
    setIsPoliciesModalOpen(prevState => !prevState)
  }

  const getTabs = () => {
    const tabs = [
      { id: TabType.Closet, title: <FormattedMessage id="profile.tabs.closet" /> },
      { id: TabType.Feedback, title: <FormattedMessage id="profile.tabs.feedback" /> },
    ] as ComponentProps<typeof Tabs>['items']

    const isInsightsTabVisible = sellerInsights?.isVisible && isViewingSelf

    if (isInsightsTabVisible) {
      tabs.push({
        id: TabType.Insights,
        children: (
          <div className="u-flexbox u-gap-medium u-align-items-center">
            <Text
              as="h3"
              // Manually handle color of text due to loss of this capacity using children prop
              theme={activeTabId === TabType.Insights ? 'amplified' : 'muted'}
              type={Text.Type.Subtitle}
            >
              <FormattedMessage id="profile.seller_insights.wardrobe_tab" />
            </Text>
            <Badge content={translate('new_badge')} theme="primary" />
          </div>
        ),
      })
    }

    if (breakpoints.phones) {
      tabs.push({ id: TabType.About, title: <FormattedMessage id="profile.tabs.about" /> })
    }

    return tabs
  }

  const renderWarningBlock = (keyId: string) => (
    <>
      <Cell body={<FormattedMessage id={keyId} />} theme="warning" />
      <Spacer size={Spacer.Size.Large} />
    </>
  )

  const renderItemsWarning = () => {
    const isViewedByGod = currentUser?.is_god
    const isViewedByModerator = currentUser?.moderator

    if (!(isViewingSelf || isViewedByGod || isViewedByModerator)) return null

    if (user.has_replica_proof_items) {
      return renderWarningBlock('profile.notification.replica_warning')
    }

    if (!user.has_item_alerts) return null

    return renderWarningBlock('profile.notification.items_warning')
  }

  const getSingleBumpableItem = () => {
    return items.find(item => item.canPushUp)
  }

  const renderClosetTab = (activeTabProps: ActiveTabProps) => {
    const { showActions } = activeTabProps

    if (breakpoints.tabletsUp) {
      return (
        <BumpCheckoutProvider>
          <div className={classNames('profile__items-wrapper')}>
            <div>
              <InfoBanner screen={screenName} theme="inverseExperimental" />
              <Spacer size={Spacer.Size.Medium} />
              <ProfileBanners
                user={user}
                showActions={showActions}
                viewingSelf={isViewingSelf}
                totalItemsCount={totalItemsCount}
                userId={user.id}
                getSingleBumpableItem={getSingleBumpableItem}
              />
              <BumpCheckout />
            </div>
            <UserItems
              userId={user.id}
              allowBump
              userOnHoliday={!!user?.is_on_holiday}
              currentUserId={currentUser?.id}
              items={items}
              itemsCount={itemsCount}
              isLoading={isLoading}
              selectedSellerFilter={selectedSellerFilter}
              sortBy={sortBy}
              catalogId={catalogId}
              catalogs={catalogs}
              fetchMoreItems={fetchMoreItems}
              updateFilters={updateFilters}
              totalItemsCount={totalItemsCount}
            />
          </div>
        </BumpCheckoutProvider>
      )
    }

    return (
      <BumpCheckoutProvider>
        <div className="u-ui-margin-large">
          <InfoBanner screen={screenName} theme="inverseExperimental" />
        </div>
        <div>
          {renderItemsWarning()}
          <SeparatedList separator={<Divider />}>
            {banners.businessAccount && isViewingSelf && (
              <BusinessAccountBanner banner={banners.businessAccount} />
            )}
            {banners.businessAccountEpr && isViewingSelf && (
              <EprBanner banner={banners.businessAccountEpr} />
            )}
            {user && (
              <ProfileInfo
                user={user}
                sellerBadgesList={sellerBadgesList}
                {...userInfo}
                showActions={showActions}
                isAvailableForMigration={isPortalMergeSourceEnabled}
                viewingSelf={isViewingSelf}
                onTogglePoliciesModal={togglePoliciesModal}
                screen={Screen.UserProfile}
                compactMode
                onUserInfoClick={changeTab(TabType.Feedback)}
                isContentTranslationEnabled={isContentTranslationEnabled}
              />
            )}
            <Spacer size={Spacer.Size.Medium} />
            <ProfileBanners
              user={user}
              showActions={showActions}
              viewingSelf={isViewingSelf}
              totalItemsCount={totalItemsCount}
              userId={user.id}
              getSingleBumpableItem={getSingleBumpableItem}
            />
          </SeparatedList>
          <Divider />
          <BumpCheckout />
        </div>
        <UserItems
          userId={user.id}
          allowBump
          userOnHoliday={!!user?.is_on_holiday}
          currentUserId={currentUser?.id}
          items={items}
          itemsCount={itemsCount}
          isLoading={isLoading}
          selectedSellerFilter={selectedSellerFilter}
          sortBy={sortBy}
          catalogId={catalogId}
          catalogs={catalogs}
          fetchMoreItems={fetchMoreItems}
          updateFilters={updateFilters}
          totalItemsCount={totalItemsCount}
        />
      </BumpCheckoutProvider>
    )
  }

  const renderAboutTab = (activeTabProps: ActiveTabProps) => {
    const { showActions } = activeTabProps

    return (
      <ProfileInfo
        user={user}
        sellerBadgesList={sellerBadgesList}
        {...userInfo}
        showActions={showActions}
        isAvailableForMigration={isPortalMergeSourceEnabled}
        viewingSelf={isViewingSelf}
        onTogglePoliciesModal={togglePoliciesModal}
        screen={Screen.UserAbout}
        onUserInfoClick={changeTab(TabType.Feedback)}
        isContentTranslationEnabled={isContentTranslationEnabled}
      />
    )
  }

  const renderFeedbackTab = () => {
    const { feedbackProps } = userInfo

    return (
      <div className={classNames('profile__items-wrapper')}>
        <FeedbackProvider>
          <ProfileFeedback
            {...feedbackProps}
            isContentTranslationEnabled={isContentTranslationEnabled}
            viewingSelf={isViewingSelf}
            userId={user.id}
            username={user.login}
            showTitle={false}
          />
        </FeedbackProvider>
      </div>
    )
  }

  const renderInsightsTab = () => {
    if (!sellerInsights) return null

    return (
      <SellerInsights
        sellerInsights={sellerInsights}
        isSellerInsightsLoading={isSellerInsightsLoading}
      />
    )
  }

  const renderActiveTab = (activeTabProps: ActiveTabProps) => {
    switch (activeTabId) {
      case TabType.Closet:
        return renderClosetTab(activeTabProps)
      case TabType.Feedback:
        return renderFeedbackTab()
      case TabType.About:
        return renderAboutTab(activeTabProps)
      case TabType.Insights:
        return renderInsightsTab()
      default:
        return null
    }
  }

  const renderPoliciesModal = () => {
    if (!user?.business) return null

    return <PoliciesModal show={isPoliciesModalOpen} onClose={togglePoliciesModal} user={user} />
  }

  const { showSupportFunctionality } = userInfo

  const showActions = !(user?.is_hated || user?.hates_you) && !!user?.allow_direct_messaging

  return (
    <PromoAfterItemUploadProvider>
      <VasEntryPointsProvider userId={user.id}>
        {user && showSupportFunctionality && <ProfileAdminSection userId={user.id} />}
        <ShowAtBreakpoint breakpoint="phones">
          <Tabs
            items={getTabs()}
            width={Tabs.Width.Parent}
            activeItemId={activeTabId}
            onClick={onTabChange}
          />
          <Divider />
          {renderActiveTab({ showActions })}
        </ShowAtBreakpoint>
        <ShowAtBreakpoint breakpoint="tabletsUp">
          <div className="container">
            <div className="profile u-flex-direction-column">
              <div>
                {renderItemsWarning()}
                {banners.businessAccount && isViewingSelf && (
                  <>
                    <BusinessAccountBanner banner={banners.businessAccount} />
                    <Spacer size={Spacer.Size.X3Large} />
                  </>
                )}
                {banners.businessAccountEpr && isViewingSelf && (
                  <>
                    <EprBanner banner={banners.businessAccountEpr} />
                    <Spacer size={Spacer.Size.X3Large} />
                  </>
                )}
                {user && (
                  <InfoHeader
                    user={user}
                    sellerBadgesList={sellerBadgesList}
                    {...userInfo}
                    showActions={showActions}
                    isAvailableForMigration={isPortalMergeSourceEnabled}
                    viewingSelf={isViewingSelf}
                    screen={screenName}
                    onTogglePoliciesModal={togglePoliciesModal}
                    onRatingClick={changeTab(TabType.Feedback)}
                    isContentTranslationEnabled={isContentTranslationEnabled}
                  />
                )}
                <Spacer orientation={Spacer.Orientation.Vertical} size={Spacer.Size.Large} />
                <span id="tabs">
                  <Tabs items={getTabs()} activeItemId={activeTabId} onClick={onTabChange} />
                </span>
                <Divider />
              </div>
              <Spacer />
              {renderActiveTab({ showActions })}
            </div>
          </div>
        </ShowAtBreakpoint>
        <PromosAfterItemUpload />
        {renderPoliciesModal()}
        <TaxpayersSpecialVerificationSuccessModal />
      </VasEntryPointsProvider>
    </PromoAfterItemUploadProvider>
  )
}

export default Profile
