import { useContext } from 'react'

import FeedbackContext from './FeedbackContext'

const useFeedbackContext = () => {
  const context = useContext(FeedbackContext)

  if (!context) {
    throw new Error('useFeedbackContext must be used within a FeedbackProvider')
  }

  return context
}

export default useFeedbackContext
