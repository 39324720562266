'use client'

import { useIntl } from 'react-intl'
import { unescape } from 'lodash'

import { linkifyContent } from '_libs/utils/linkify'
import { LinkReplacement } from 'types/linkify'
import useSystemConfiguration from 'hooks/useSystemConfiguration'

import useLeavingVintedModal from './hooks/useLeavingVintedModal'
import LeavingVintedModalExperiment from './LeavingVintedModalExperiment'

const TRANSLATION_KEY = 'linkified-message'

const defaultLink = (href: string, text: string) => <a href={href}>{text}</a>

type Props = {
  text: string
  renderUrl?: LinkReplacement
  renderHashtag?: LinkReplacement
  escapeText?: boolean
  isLeavingVintedModalWarningEnabled?: boolean
}

const LinkifiedMessage = ({
  text,
  escapeText,
  renderUrl = defaultLink,
  renderHashtag = defaultLink,
  isLeavingVintedModalWarningEnabled = true,
}: Props) => {
  const { messages, formatMessage } = useIntl()
  const { isLeavingVintedModalVisible, url, handleLinkClick, handleOnContinue, handleCancel } =
    useLeavingVintedModal()
  const urlRegexConfig = useSystemConfiguration()?.urlRegex

  const urlRegex = urlRegexConfig ? new RegExp(urlRegexConfig, 'g') : undefined

  // TODO: remove this check or make TS typings more accurate
  if (!text) return null

  const renderProtectedUrl = (href: string, formattedText: string) => (
    <a href={href} onClick={event => handleLinkClick(event, href)}>
      {formattedText}
    </a>
  )

  const linkifiedContent = linkifyContent(
    unescape(text),
    {
      url: isLeavingVintedModalWarningEnabled ? renderProtectedUrl : renderUrl,
      hashtag: renderHashtag,
    },
    escapeText,
    urlRegex,
  )

  messages[TRANSLATION_KEY] = linkifiedContent.text

  return (
    <>
      <span>{formatMessage({ id: TRANSLATION_KEY }, linkifiedContent.values)}</span>
      {isLeavingVintedModalWarningEnabled && (
        <LeavingVintedModalExperiment
          isOpen={isLeavingVintedModalVisible}
          url={url}
          onCancel={handleCancel}
          onContinue={handleOnContinue}
        />
      )}
    </>
  )
}

export default LinkifiedMessage
