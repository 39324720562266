'use client'

import { useState, useEffect } from 'react'
import { Text, Cell, Spacer } from '@vinted/web-ui'

import { UserDto } from 'types/dtos'
import { Translation } from 'constants/translation'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { getUserById } from 'data/api'

import useFetch from 'hooks/useFetch'
import useTranslate from 'hooks/useTranslate'

import { LinkifiedMessage } from 'components/LinkifiedMessage'
import TranslationControl from 'components/TranslationControl'

type Props = {
  user: UserDto
  isContentTranslationEnabled: boolean
}

const MAX_SYMBOL_COUNT = 220

function truncateDescription(description: string) {
  return description.substring(0, MAX_SYMBOL_COUNT).split('\n')[0]!.trim()
}

const UserDescription = ({ user, isContentTranslationEnabled }: Props) => {
  const translate = useTranslate('user.description')
  const [isTruncated, setIsTruncated] = useState(false)
  const [isContentTranslated, setIsContentTranslated] = useState(false)
  const [translatedDescription, setTranslatedDescription] = useState('')

  const { fetch: fetchUserById, data: userData } = useFetch(getUserById)

  useEffect(() => {
    if (!user.about) return

    const truncatedDescription = truncateDescription(user.about)

    if (user.about !== truncatedDescription) {
      setIsTruncated(true)
    }
  }, [user.about])

  useEffect(() => {
    if (!userData) return

    const { about } = userData.user

    if (!about) return

    setTranslatedDescription(about)
    setIsContentTranslated(true)
  }, [userData])

  async function translateDescription() {
    if (translatedDescription) {
      setIsContentTranslated(true)

      return
    }

    await fetchUserById({ id: user.id, localize: true })
  }

  function showOriginal() {
    setIsContentTranslated(false)
  }

  function handleTranslate(isTranslated: boolean) {
    return isTranslated ? showOriginal() : translateDescription()
  }

  function toggleIsTruncated() {
    setIsTruncated(prevIsTruncated => !prevIsTruncated)
  }

  const fullDescription =
    isContentTranslated && translatedDescription ? translatedDescription : user.about

  if (!fullDescription) return null

  const activeDescription = isTruncated
    ? `${truncateDescription(fullDescription)}...`
    : fullDescription
  const showTranslationControl =
    isContentTranslationEnabled && user.localization !== Translation.None

  return (
    <Cell>
      <Text as="span" format>
        <div className="profile__user-description" data-testid="profile-user-description">
          <LinkifiedMessage text={activeDescription} />
          {isTruncated && (
            <button
              type="button"
              className="u-ui-margin-left-small"
              onClick={toggleIsTruncated}
              data-testid="more-button"
            >
              <Text as="span" clickable>
                {translate('actions.more')}
              </Text>
            </button>
          )}
        </div>
      </Text>
      {showTranslationControl && (
        <>
          <Spacer />
          <TranslationControl
            isTranslated={isContentTranslated}
            autotranslate={false}
            onClick={handleTranslate}
            tracking={{
              target: ClickableElement.TranslateDescription,
              targetDetails: user.id.toString(),
            }}
          />
        </>
      )}
    </Cell>
  )
}

export default UserDescription
