import { intersection, compact, isEmpty } from 'lodash'

import { CatalogItem } from 'types/catalog-filters'
import { SizeCategoryDto } from 'types/dtos'
import { CatalogModel, SizeGroupModel } from 'types/models'
import { transformSizeGroupModelsToFilterGroupModels } from 'data/transformers/size-group'

export const catalogsInAscOrder = (catalog: CatalogModel, other: CatalogModel) =>
  catalog.order - other.order

export const catalogModelToCatalogListItem = (catalog: CatalogModel): CatalogItem => {
  const { id, title, catalogIds, parentId, code, depth, photo, badge } = catalog

  return {
    id,
    title,
    value: id,
    data: {
      id,
      catalogIds,
      parentId,
      code,
      depth,
      photo,
      badge,
    },
  }
}

export const catalogModelsToCatalogListItems = (
  catalogs: Array<CatalogModel>,
): Array<CatalogItem> => catalogs.map(catalogModelToCatalogListItem)

export const getNewPriceRange = (
  priceFrom: string | null | undefined,
  priceTo: string | null | undefined,
) => {
  if (priceFrom && priceTo && parseFloat(priceFrom) > parseFloat(priceTo)) {
    return { priceFrom: priceTo, priceTo: null }
  }

  return { priceTo, priceFrom }
}

export type GetMatchingSizeFilterGroupModelsArgs = {
  selectedCatalogCode: string | null
  sizeCategories: Array<SizeCategoryDto>
  sizeGroups: Array<SizeGroupModel>
  selectedCatalogs: Array<CatalogModel>
}

export const getMatchingSizeFilterGroupModels = ({
  selectedCatalogCode,
  sizeCategories,
  sizeGroups,
  selectedCatalogs,
}: GetMatchingSizeFilterGroupModelsArgs) => {
  if (!selectedCatalogCode) return []

  const sizeCategory = sizeCategories.find(it => it.code === selectedCatalogCode)

  if (!sizeCategory) return []

  const catalogsToSizeGroupIds = (catalog: CatalogModel) =>
    catalog.sizeGroupId === null
      ? catalog.sizeGroupIds
      : [...catalog.sizeGroupIds, catalog.sizeGroupId]

  const catalogSizeGroupIds = selectedCatalogs.flatMap(catalogsToSizeGroupIds)
  const availableSizeGroupIds = isEmpty(selectedCatalogs)
    ? sizeCategory.size_groups
    : intersection(sizeCategory.size_groups, catalogSizeGroupIds)

  const sizeGroupModels = compact(
    availableSizeGroupIds.map(id => sizeGroups.find(it => it.id === id)),
  )

  return transformSizeGroupModelsToFilterGroupModels(sizeGroupModels)
}

export const getMatchingSizeCategories = (
  sizeCategories: Array<SizeCategoryDto>,
  selectedCatalogs: Array<CatalogModel>,
): Array<SizeCategoryDto> => {
  if (isEmpty(selectedCatalogs)) return sizeCategories

  const catalogsToSizeGroupIds = (catalog: CatalogModel) =>
    catalog.sizeGroupId === null
      ? catalog.sizeGroupIds
      : [...catalog.sizeGroupIds, catalog.sizeGroupId]

  const availableSizeGroupIds = selectedCatalogs.flatMap(catalogsToSizeGroupIds)

  const filter = (category: SizeCategoryDto) =>
    !isEmpty(intersection(availableSizeGroupIds, category.size_groups))

  return sizeCategories.filter(filter)
}
