import { useEffect } from 'react'

import useFetch from 'hooks/useFetch'
import { getSustainabilityImpact } from 'data/api'
import useAbTest from 'hooks/useAbTest'

const useGetSustainabilityImpact = () => {
  const {
    fetch: fetchSustainabilityImpact,
    isLoading: isSustainabilityImpactLoading,
    data: sustainabilityImpact,
  } = useFetch(getSustainabilityImpact)

  const sustainabilityImpactAbTest = useAbTest({
    abTestName: 'seller_insights_sustainability',
    shouldTrackExpose: true,
  })

  const isSustainabilityImpactAbTestEnabled =
    sustainabilityImpactAbTest && sustainabilityImpactAbTest.variant !== 'off'

  useEffect(() => {
    if (!isSustainabilityImpactAbTestEnabled) return

    fetchSustainabilityImpact()
  }, [fetchSustainabilityImpact, isSustainabilityImpactAbTestEnabled])

  return {
    sustainabilityImpact: sustainabilityImpact?.sustainability_impacts,
    isSustainabilityImpactLoading,
  }
}

export default useGetSustainabilityImpact
