import { FeedbackSummaryDto } from 'types/dtos'
import { FeedbackSummaryModel } from 'types/models'

export const transformFeedbackSummaryDto = (summary: FeedbackSummaryDto): FeedbackSummaryModel => ({
  feedbackCount: summary.feedback_count,
  feedbackRating: summary.feedback_rating,
  userFeedbackCount: summary.user_feedback_count,
  userFeedbackRating: summary.user_feedback_rating,
  systemFeedbackCount: summary.system_feedback_count,
  systemFeedbackRating: summary.system_feedback_rating,
})
