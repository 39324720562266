'use client'

import { ReactNode, Component } from 'react'

import { Breakpoints } from 'types/components'

import withBreakpoint, { BreakpointProps } from './withBreakpoint'

type Props = BreakpointProps & {
  children: ReactNode
  breakpoint: keyof Breakpoints
}

class HideAtBreakpoint extends Component<Props> {
  render() {
    const { breakpoints, breakpoint } = this.props
    const matchedBreakpoint = breakpoints[breakpoint]

    return matchedBreakpoint ? null : this.props.children
  }
}

export default withBreakpoint(HideAtBreakpoint)
