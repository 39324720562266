'use client'

import { ReactNode } from 'react'
import { Button, Cell, Image, Spacer, Text } from '@vinted/web-ui'
import classNames from 'classnames'

import useAsset from 'hooks/useAsset'
import useBreakpoint from 'hooks/useBreakpoint'
import useClosetCountdown from 'hooks/useClosetCountdown'
import useTranslate from 'hooks/useTranslate'

import FollowButton from 'components/FollowButton'
import SeparatedList from 'components/SeparatedList'

type Props = {
  endDate: string
  bottomElement?: ReactNode
  userId: number | undefined
  username: string | undefined
  isFollowed: boolean | undefined
  viewingSelf: boolean
  showActions: boolean
}

const ICON_NAME_64 = 'clothes-star-64.png'
const ICON_NAME_96 = 'clothes-star-96.png'

const ProfileCountdownBanner = ({
  endDate,
  bottomElement,
  username,
  isFollowed,
  userId,
  viewingSelf,
  showActions,
}: Props) => {
  const { formattedRemainingTime, isCountdownActive } = useClosetCountdown(endDate)
  const translate = useTranslate('closet_countdown')
  const breakpoints = useBreakpoint()
  const asset = useAsset('/assets/closet-countdown')

  const hideFollowAction = !userId || isFollowed === undefined || viewingSelf || !showActions

  const contentClasses = classNames('closet-countdown__content', {
    'closet-countdown__content--hidden-action': hideFollowAction,
  })

  const iconName = breakpoints.tabletsUp ? ICON_NAME_96 : ICON_NAME_64
  const iconPath = asset(iconName, { theme: { dark: `dark/${iconName}` } })

  if (!isCountdownActive) return null

  const renderCountdown = () => {
    if (!formattedRemainingTime) return null

    const { days, hours, minutes, seconds } = formattedRemainingTime

    return (
      <ul className="closet-countdown__timer">
        <SeparatedList separator={<Spacer orientation={Spacer.Orientation.Vertical} />}>
          <li className="closet-countdown__timer-unit">
            <div className="closet-countdown__timer-unit-value">{days}</div>
            <div className="closet-countdown__timer-unit-name">
              {translate('time.days', undefined, { count: days })}
            </div>
          </li>
          <li className="closet-countdown__timer-unit">
            <div className="closet-countdown__timer-unit-value">{hours}</div>
            <div className="closet-countdown__timer-unit-name">
              {translate('time.hours', undefined, { count: hours })}
            </div>
          </li>
          <li className="closet-countdown__timer-unit">
            <div className="closet-countdown__timer-unit-value">{minutes}</div>
            <div className="closet-countdown__timer-unit-name">
              {translate('time.minutes', undefined, { count: minutes })}
            </div>
          </li>
          <li className="closet-countdown__timer-unit">
            <div className="closet-countdown__timer-unit-value">{seconds}</div>
            <div className="closet-countdown__timer-unit-name">
              {translate('time.seconds', undefined, { count: seconds })}
            </div>
          </li>
        </SeparatedList>
      </ul>
    )
  }

  const renderTitle = () => {
    if (!userId || isFollowed === undefined || viewingSelf || !showActions) return null

    if (breakpoints.tabletsUp) {
      return <h2 className="closet-countdown__title">{translate('title', { username })}</h2>
    }

    return (
      <>
        <Spacer size={Spacer.Size.XLarge} />
        <Text as="h2" type={Text.Type.Title}>
          {translate('title', { username })}
        </Text>
      </>
    )
  }

  const renderFollowButton = () => {
    if (!userId || isFollowed === undefined || viewingSelf || !showActions) return null

    return (
      <FollowButton
        userId={userId}
        username={username}
        isActive={isFollowed}
        size={Button.Size.Medium}
      />
    )
  }

  if (breakpoints.tabletsUp) {
    return (
      <>
        <section className="u-flexbox u-align-items-center u-justify-content-between u-padding-horizontal-medium">
          <div className={contentClasses}>
            <div>
              <Image src={iconPath} size={Image.Size.X2Large} alt="" />
            </div>
            <div className="u-flexbox u-flex-direction-column">
              <Text as="span" theme="amplified">
                {translate('subtitle')}
              </Text>
              <Spacer />
              {renderCountdown()}
            </div>
            {renderTitle()}
          </div>
          <div className="closet-countdown__button">{renderFollowButton()}</div>
        </section>
        {bottomElement}
      </>
    )
  }

  return (
    <div className="closet-countdown">
      <Cell styling={Cell.Styling.Tight}>
        <div className="closet-countdown__image">
          <Image src={iconPath} size={Image.Size.XLarge} alt="" />
        </div>
        <Text as="span" theme="amplified">
          {translate('subtitle')}
        </Text>
        <Spacer />
        {renderCountdown()}
        {renderTitle()}
        <Spacer size={Spacer.Size.XLarge} />
        {renderFollowButton()}
        {bottomElement}
      </Cell>
    </div>
  )
}

export default ProfileCountdownBanner
