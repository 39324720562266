import { CurrencyAmountModel } from 'types/models'

export enum SellerInsightsPhotoRecommendationsType {
  NeedsImprovement = 'items_need_improvement',
  AllImproved = 'all_items_improved',
  NoItems = 'no_items_found',
}

export type SellerInsightsType = {
  isVisible: boolean
  activeItemsStats: {
    count: number
    total: CurrencyAmountModel
  }
  soldItemsStats: {
    total: CurrencyAmountModel
    period: {
      duration: string
      total: CurrencyAmountModel
    }
  }
}
