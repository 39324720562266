import { Translation } from 'constants/translation'

export const COMMENT_CHAR_LIMIT = 400

export type FeedbackUserModel = {
  id: number
  login: string
  profileUrl: string
  photoUrl?: string
}

export type FeedbackCommentModel = {
  user?: FeedbackUserModel
  isSystem: boolean
  text: string
}

export type FeedbackModel = {
  id: number
  user?: FeedbackUserModel
  text: string
  rating: number
  createdAt: string
  comment?: FeedbackCommentModel
  isSystem: boolean
  canReply: boolean
  canChange: boolean
  canDelete: boolean
  canChangeComment: boolean
  canDeleteComment: boolean
  canModerate: boolean
  localization?: Translation
}

export type FeedbackPaginationModel = {
  currentPage: number
  totalPages: number
}

export type FeedbackListModel = {
  feedback: Array<FeedbackModel>
  pagination: FeedbackPaginationModel
}
