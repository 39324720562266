import { FormattedMessage } from 'components/@support'
import { FeedbackUserModel } from 'types/models'

type Props = {
  user?: FeedbackUserModel
  isSystem?: boolean
}

const FeedbackUsername = ({ user, isSystem = false }: Props) => {
  if (isSystem) {
    return <FormattedMessage id="user.system_name" />
  }

  if (user) {
    return (
      <a className="u-disable-underline" href={user.profileUrl}>
        {user.login}
      </a>
    )
  }

  return <FormattedMessage id="user.deleted_user" />
}

export default FeedbackUsername
