'use client'

import { Card, Divider, Spacer, Text, Button, Cell, Icon } from '@vinted/web-ui'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

import { InfoCircle24, Money24 } from '@vinted/monochrome-icons'

import { formatCurrencyAmount } from '_libs/utils/formatString'
import { clickEvent, viewEvent } from '_libs/common/event-tracker/events'
import { FormattedMessage } from 'components/@support'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { Screen } from 'constants/tracking/screens'
import { ViewableElement } from 'constants/tracking/viewable-elements'
import { SellerInsightsType } from 'constants/seller-insights'

import useTranslate from 'hooks/useTranslate'
import useTracking from 'hooks/useTracking'
import useAbTest from 'hooks/useAbTest'

import SellerInsightsValuePropositionModal from '../SellerInsightsValuePropositionModal/SellerInsightsValuePropositionModal'

type Props = {
  sellerInsights: SellerInsightsType
}

const SellerInsightsBlocks = ({ sellerInsights }: Props) => {
  const { track } = useTracking()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const { locale } = useIntl()
  const translate = useTranslate('profile.seller_insights')

  const activeItemsPrice = sellerInsights?.activeItemsStats.total
  const soldItemsPrice = sellerInsights?.soldItemsStats.total
  const recentItemsPrice = sellerInsights?.soldItemsStats.period.total
  const recentItemsPeriod = sellerInsights?.soldItemsStats.period.duration

  const sellerInsightsTipsBannerTest = useAbTest({
    abTestName: 'seller_insights_tip_banner',
  })
  const isSellerInsightsTipsBannerEnabled =
    sellerInsightsTipsBannerTest && sellerInsightsTipsBannerTest.variant !== 'off'

  useEffect(() => {
    track(
      viewEvent({
        target: ViewableElement.SellerInsightsBlocks,
        screen: Screen.CurrentUserProfile,
      }),
    )
  }, [track])

  const handleOpenModal = () => {
    track(
      clickEvent({
        target: ClickableElement.SellerInsightsInfo,
        screen: Screen.CurrentUserProfile,
      }),
    )
    setIsModalOpen(true)
  }

  const handleCloseModal = () => setIsModalOpen(false)

  const renderHeader = () => (
    <Cell
      prefix={<Icon name={Money24} color={Icon.Color.GreyscaleLevel3} />}
      title={translate('title_secondary')}
      suffix={
        <Button
          inline
          iconName={InfoCircle24}
          size={Button.Size.Small}
          styling={Button.Styling.Flat}
          iconColor={Icon.Color.GreyscaleLevel3}
          onClick={handleOpenModal}
          testId="seller-insights-info"
          aria={{ 'aria-label': translate('link') }}
        />
      }
      styling={Cell.Styling.Tight}
      testId="seller-insights-header-v2"
    />
  )

  const renderSellerInsightsV1Body = () => (
    <>
      <div className="u-flexbox u-flex-direction-row u-align-items-center u-justify-content-between">
        {renderHeader()}
      </div>
      <Spacer size={Spacer.Size.Medium} />
      <Card>
        <Cell testId="seller-insights-blocks" styling={Cell.Styling.Tight}>
          <div className="u-flexbox u-flex-direction-row u-padding-medium">
            <section className="u-flexbox u-flex-direction-column u-align-items-center u-half-width">
              <Text
                text={translate('blocks.wardrobe_value.title')}
                type={Text.Type.Caption}
                as="h3"
              />
              <Text
                text={activeItemsPrice ? formatCurrencyAmount(activeItemsPrice, locale) : ''}
                type={Text.Type.Title}
                as="h2"
                testId="seller-insights-wardrobe-value"
              />
              <Text type={Text.Type.Caption} as="h3">
                <FormattedMessage
                  pluralize
                  count={sellerInsights?.activeItemsStats.count}
                  id="profile.seller_insights.blocks.wardrobe_value.subtitle"
                  values={{ wardrobe_items_count: sellerInsights?.activeItemsStats.count }}
                />
              </Text>
            </section>
            <Divider orientation={Divider.Orientation.Vertical} />
            <section className="u-flexbox u-flex-direction-column u-align-items-center u-half-width">
              <Text text={translate('blocks.earnings.title')} type={Text.Type.Caption} as="h3" />
              <Text
                text={soldItemsPrice ? formatCurrencyAmount(soldItemsPrice, locale) : ''}
                type={Text.Type.Title}
                as="h2"
                testId="seller-insights-earnings"
              />
              <Text type={Text.Type.Caption} theme="success" as="h3">
                {translate('blocks.earnings.subtitle', {
                  total_earned: recentItemsPrice
                    ? formatCurrencyAmount(recentItemsPrice, locale)
                    : '',
                })}
              </Text>
            </section>
          </div>
        </Cell>
      </Card>
    </>
  )

  const renderSellerInsightsWithTipsBannerBody = () => (
    <Card>
      <div className="seller-insights-atom u-padding-large">
        <Cell
          prefix={
            <Icon
              name={Money24}
              color={Icon.Color.GreyscaleLevel3}
              aria={{ 'aria-hidden': true }}
            />
          }
          title={translate('title_secondary')}
          body={translate('subtitle')}
          suffix={
            <Button
              inline
              iconName={InfoCircle24}
              size={Button.Size.Small}
              styling={Button.Styling.Flat}
              iconColor={Icon.Color.GreyscaleLevel3}
              onClick={handleOpenModal}
              testId="seller-insights-info"
              aria={{ 'aria-label': translate('link') }}
            />
          }
          styling={Cell.Styling.Tight}
        />
        <Cell testId="seller-insights-blocks" styling={Cell.Styling.Tight}>
          <div className="u-flexbox u-flex-direction-row u-padding-medium">
            <section className="u-flexbox u-flex-direction-column u-align-items-center u-half-width u-padding-right-medium">
              <Text
                alignment={Text.Alignment.Center}
                text={translate('blocks.wardrobe_value.title')}
                type={Text.Type.Caption}
                as="h3"
              />
              <Text
                text={activeItemsPrice ? formatCurrencyAmount(activeItemsPrice, locale) : ''}
                type={Text.Type.Title}
                as="h2"
                testId="seller-insights-wardrobe-value"
              />
              <Text alignment={Text.Alignment.Center} type={Text.Type.Caption} as="h3">
                <FormattedMessage
                  pluralize
                  count={sellerInsights?.activeItemsStats.count}
                  id="profile.seller_insights.blocks.wardrobe_value.subtitle"
                  values={{ wardrobe_items_count: sellerInsights?.activeItemsStats.count }}
                />
              </Text>
            </section>
            <Divider orientation={Divider.Orientation.Vertical} />
            <section className="u-flexbox u-flex-direction-column u-align-items-center u-half-width u-padding-left-medium">
              <Text
                alignment={Text.Alignment.Center}
                text={translate('blocks.earnings.title')}
                type={Text.Type.Caption}
                as="h3"
              />
              <Text
                text={soldItemsPrice ? formatCurrencyAmount(soldItemsPrice, locale) : ''}
                type={Text.Type.Title}
                as="h2"
                testId="seller-insights-earnings"
              />
              <Text
                alignment={Text.Alignment.Center}
                type={Text.Type.Caption}
                theme="success"
                as="h3"
              >
                {translate('blocks.earnings.subtitle', {
                  total_earned: recentItemsPrice
                    ? formatCurrencyAmount(recentItemsPrice, locale)
                    : '',
                })}
              </Text>
            </section>
          </div>
        </Cell>
      </div>
    </Card>
  )

  return (
    <div className="u-flexbox u-flex-direction-column u-flex-grow u-flex-1">
      <Spacer size={Spacer.Size.XLarge} />
      {isSellerInsightsTipsBannerEnabled
        ? renderSellerInsightsWithTipsBannerBody()
        : renderSellerInsightsV1Body()}
      <SellerInsightsValuePropositionModal
        show={isModalOpen}
        onCloseOrConfirm={handleCloseModal}
        period={recentItemsPeriod}
      />
    </div>
  )
}

export default SellerInsightsBlocks
