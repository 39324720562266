'use client'

import { Fragment } from 'react'
import { Card } from '@vinted/web-ui'

import useTranslate from 'hooks/useTranslate'
import useAbTest from 'hooks/useAbTest'
import useBreakpoint from 'hooks/useBreakpoint'
import useSession from 'hooks/useSession'

import { FormattedMessage } from 'components/@support'
import BundleEntryBanner from 'components/BundleEntryBanner'
import StartBundleButtonWrapper from 'components/StartBundleButtonWrapper'

import { isDateInTheFuture } from '_libs/utils/date'
import { isBundlingAvailable } from '_libs/utils/bundle'
import { Screen } from 'constants/tracking/screens'
import { CREATE_BUNDLE_URL } from 'constants/routes'
import { AbTestVariant } from 'constants/abtest'
import { transformUserBundleDiscountDto } from 'data/transformers/bundle-discount'

import { UserDto } from 'types/dtos'

import VasBanner from '../VasBanner'

type Props = {
  user: UserDto | null | undefined
  totalItemsCount: number
  userId: number
  renderCountDownBanner: () => JSX.Element | null
}

const ProfileVisitorBanners = ({ user, totalItemsCount, userId, renderCountDownBanner }: Props) => {
  const bundleTranslate = useTranslate('bundles')
  const breakpoints = useBreakpoint()

  const isCurrentUserAuthenticated = !!useSession().user

  const isBundleEntryBannerAlignAbTestEnabled =
    useAbTest({
      abTestName: 'dd_tx_web_bundle_entry_banner_align',
      shouldTrackExpose: true,
    })?.variant === AbTestVariant.On

  const { bundle_discount: bundleDiscount, closet_countdown_end_date: closetCountdownEndDate } =
    user || {}

  if (closetCountdownEndDate && isDateInTheFuture(closetCountdownEndDate))
    return renderCountDownBanner()

  const showBundles = isBundlingAvailable({
    canBundle: !!user?.can_bundle,
    totalItemCount: totalItemsCount,
    isCurrentUserAuthenticated,
    closetCountdownEndDate,
    hasHateFromAnySide: !!user?.is_hated || !!user?.hates_you,
    isViewingOwnProfile: false,
  })

  if (!showBundles) return null

  const getBundleDiscountText = () => {
    if (!bundleDiscount?.enabled) {
      return bundleTranslate('discounts.not_available')
    }

    const fraction = Math.max(...bundleDiscount.discounts.map(discount => +discount.fraction))

    return bundleTranslate('discounts.available_up_to', {
      percentage: Math.round(fraction * 100),
    })
  }

  const transformedBundleDiscounts = bundleDiscount
    ? transformUserBundleDiscountDto(bundleDiscount)
    : null

  const Wrapper = breakpoints.tabletsUp ? Card : Fragment

  return isBundleEntryBannerAlignAbTestEnabled ? (
    <Wrapper>
      <BundleEntryBanner
        itemUserId={userId}
        bundleDiscount={transformedBundleDiscounts}
        testId="profile-bundle-banner"
      />
    </Wrapper>
  ) : (
    <StartBundleButtonWrapper url={CREATE_BUNDLE_URL(userId)} screen={Screen.UserProfile}>
      {({ onClick }) => (
        <VasBanner
          title={<FormattedMessage id="bundles.shop_title" />}
          subtitle={getBundleDiscountText()}
          onClick={onClick}
          testId="profile-banner-shop-bundles-button"
        />
      )}
    </StartBundleButtonWrapper>
  )
}

export default ProfileVisitorBanners
