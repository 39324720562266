'use client'

import { Button, Cell, Divider, Dialog, Navigation, Spacer } from '@vinted/web-ui'
import { X24 } from '@vinted/monochrome-icons'

import useTranslate from 'hooks/useTranslate'
import SeparatedList from 'components/SeparatedList'

type Props = {
  show: boolean
  onCloseOrConfirm: () => void
  period?: string
}

type ContentRow = {
  title: string
  explanation: string
}

const SellerInsightsValuePropositionModal = ({ show, onCloseOrConfirm, period }: Props) => {
  const translate = useTranslate('profile.seller_insights.modal')
  const translateRows = useTranslate('profile.seller_insights.modal.body.blocks')

  const contentRows: Array<ContentRow> = [
    {
      title: translateRows('wardrobe_value.title'),
      explanation: translateRows('wardrobe_value.explanation'),
    },
    { title: translateRows('earnings.title'), explanation: translateRows('earnings.explanation') },
    ...(period
      ? [
          {
            title: translateRows('recent.title'),
            explanation: translateRows('recent.explanation', { period }),
          },
        ]
      : []),
  ]

  const handleOnClose = () => {
    onCloseOrConfirm()
  }

  const renderModalBody = () => {
    return (
      <div className="u-fill-width">
        <Navigation
          body={translate('title')}
          right={
            <Button
              inverse
              inline
              iconName={X24}
              onClick={handleOnClose}
              aria={{
                'aria-label': translate('a11y.actions.close'),
              }}
            />
          }
        />
        <Divider />
        <Cell>
          <SeparatedList separator={<Spacer size={Spacer.Size.Large} />}>
            {contentRows.map(({ title, explanation }) => (
              <Cell
                key={`seller-insights-modal-bullet-${title}`}
                title={title}
                body={explanation}
                styling={Cell.Styling.Tight}
              />
            ))}
          </SeparatedList>
          <Spacer size={Spacer.Size.XLarge} />
          <Button
            text={translate('body.actions.confirm')}
            styling={Button.Styling.Filled}
            size={Button.Size.Medium}
            onClick={onCloseOrConfirm}
            testId="seller-insights-modal-confirm-button"
          />
        </Cell>
      </div>
    )
  }

  return (
    <Dialog
      hasScrollableContent
      defaultCallback={handleOnClose}
      show={show}
      testId="seller-insights-value-proposition-modal"
    >
      {renderModalBody()}
    </Dialog>
  )
}

export default SellerInsightsValuePropositionModal
