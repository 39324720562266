'use client'

import { useEffect } from 'react'

import { Cell, Icon, Image, Spacer } from '@vinted/web-ui'
import { CheckCircleFilled24, LightBulb24 } from '@vinted/monochrome-icons'

import useTranslate from 'hooks/useTranslate'
import useTracking from 'hooks/useTracking'
import useAsset from 'hooks/useAsset'

import { viewEvent } from '_libs/common/event-tracker/events'
import { ViewableElement } from 'constants/tracking/viewable-elements'
import { Screen } from 'constants/tracking/screens'
import { SellerInsightsPhotoRecommendationsType } from 'constants/seller-insights'
import { ItemModel } from 'types/models'

import SellerInsightsPhotoThumbnail from './SellerInsightsItemThumbnail'

type Props = {
  status: SellerInsightsPhotoRecommendationsType
  itemsToImprove: Array<{
    itemId: ItemModel['id']
    photoUrl: string
    accessibilityDescription: string
  }>
}

const SellerInsightsPhotoRecommendations = ({ status, itemsToImprove }: Props) => {
  const translate = useTranslate('profile.seller_insights.photo_recommendations')
  const asset = useAsset('/assets')
  const { track } = useTracking()

  useEffect(() => {
    track(
      viewEvent({
        target: ViewableElement.SellerInsightsPhotoRecommendations,
        screen: Screen.CurrentUserProfile,
        targetDetails: JSON.stringify({
          status,
        }),
      }),
    )
  }, [track, status])

  const renderTitle = () => {
    return status === SellerInsightsPhotoRecommendationsType.NeedsImprovement
      ? translate('header')
      : translate('empty_case.header')
  }

  const renderBody = () => {
    switch (status) {
      case SellerInsightsPhotoRecommendationsType.AllImproved:
        return translate('empty_case.body_all_good')
      case SellerInsightsPhotoRecommendationsType.NoItems:
        return translate('empty_case.body_empty')
      default:
        return translate('body')
    }
  }

  const renderPrefix = () => {
    return status === SellerInsightsPhotoRecommendationsType.AllImproved ? (
      <Icon name={CheckCircleFilled24} color={Icon.Color.Primary} />
    ) : (
      <Icon name={LightBulb24} color={Icon.Color.GreyscaleLevel3} />
    )
  }

  const renderThumbnails = () => {
    if (itemsToImprove)
      return (
        <>
          <Spacer size={Spacer.Size.XLarge} />
          <div className="grid-container">
            {itemsToImprove.map(({ itemId, photoUrl, accessibilityDescription }) => (
              <SellerInsightsPhotoThumbnail
                key={`item-to-improve${itemId}`}
                photoUrl={photoUrl}
                accessibilityDescription={accessibilityDescription}
                itemId={itemId}
              />
            ))}
          </div>
        </>
      )

    return null
  }

  return (
    <>
      <Spacer size={Spacer.Size.X2Large} />
      <Cell
        title={renderTitle()}
        body={renderBody()}
        prefix={renderPrefix()}
        styling={Cell.Styling.Tight}
        testId="seller-insights-photo-tips"
      />
      {renderThumbnails()}
      {status === SellerInsightsPhotoRecommendationsType.AllImproved && (
        <>
          <Spacer size={Spacer.Size.X2Large} />
          <div className="u-flexbox u-align-items-center">
            <Image
              size={Image.Size.X4Large}
              src={asset('check-person-sitting.svg')}
              testId="all-improved-image"
            />
          </div>
        </>
      )}
    </>
  )
}

export default SellerInsightsPhotoRecommendations
