import { useEffect, useState } from 'react'

import useFetch from 'hooks/useFetch'

import { getProfileUserInfo } from 'data/api'
import { transformProfileUserInfoResponse } from 'data/api/transformers/response'

type Props = {
  userId: number
}

type ProfileUserInfo = {
  showSupportFunctionality: boolean
  canSendTemplates: boolean
  feedbackProps: {
    canAddFeedback: boolean
    leaveFeedbackUrl: string | undefined
  }
  isShadowBanned: boolean | undefined
  isPermanentlyBanned: boolean | undefined
  isTemporarilyBanned: boolean | undefined
  isCompromised: boolean | undefined
}

const useFetchProfileUserInfo = ({ userId }: Props) => {
  const [userInfo, setUserInfo] = useState<ProfileUserInfo>({
    showSupportFunctionality: false,
    canSendTemplates: false,
    feedbackProps: {
      canAddFeedback: false,
      leaveFeedbackUrl: undefined,
    },
    isShadowBanned: undefined,
    isPermanentlyBanned: undefined,
    isTemporarilyBanned: undefined,
    isCompromised: undefined,
  })

  const { fetch: fetchProfileUserInfo, transformedData: profileUserInfo } = useFetch(
    getProfileUserInfo,
    transformProfileUserInfoResponse,
  )

  useEffect(() => {
    fetchProfileUserInfo(userId)
  }, [fetchProfileUserInfo, userId])

  useEffect(() => {
    if (profileUserInfo) {
      setUserInfo({
        showSupportFunctionality: profileUserInfo.showSupportFunctionality,
        canSendTemplates: profileUserInfo.canSendTemplates,
        feedbackProps: {
          canAddFeedback: profileUserInfo.canAddFeedback,
          leaveFeedbackUrl:
            typeof profileUserInfo.urlForLastUnfeedbackedTransaction === 'string'
              ? profileUserInfo.urlForLastUnfeedbackedTransaction
              : undefined,
        },
        isShadowBanned: profileUserInfo.shadowBanned,
        isPermanentlyBanned: profileUserInfo.isPermanentlyBanned,
        isTemporarilyBanned: profileUserInfo.isTemporarilyBanned,
        isCompromised: profileUserInfo.isCompromised,
      })
    }
  }, [profileUserInfo])

  return {
    userInfo,
  }
}

export default useFetchProfileUserInfo
