import { noop } from 'lodash'
import { createContext } from 'react'

import { UiState } from 'constants/ui'
import { ResponseError } from 'types/api'
import { FeedbackModel } from 'types/models'
import { FeedbackFilterType } from 'pages/Profile/types'

export enum FeedbackScreen {
  View = 'VIEW',
  DeleteFeedback = 'DELETE_FEEDBACK',
  ModerateFeedback = 'MODERATE_FEEDBACK',
  CreateComment = 'CREATE_COMMENT',
  EditComment = 'EDIT_COMMENT',
  DeleteComment = 'DELETE_COMMENT',
}

export type FeedbackItem = {
  uiState: UiState
  screen: FeedbackScreen
  error: ResponseError | null | undefined
  isTranslated: boolean
}

export type FeedbackContextType = {
  feedbackItemStateById: {
    [id: number]: FeedbackItem
  }
  ids: Array<number>
  byId: {
    [id: number]: FeedbackModel
  }
  byTranslatedId: {
    [id: number]: FeedbackModel
  }
  pagination: { currentPage: number; totalPages: number }
  uiState: UiState
  showFilters?: boolean
  editComment: (id: number, comment: string) => Promise<void>
  updateFeedbackItemStateById: (id: number, newState: Partial<FeedbackItem>) => void
  initiateTranslation: (id: number, localize: boolean) => Promise<void>
  deleteCommentByFeedbackId: (id: number) => Promise<void>
  deleteFeedbackById: (id: number) => Promise<void>
  moderateFeedbackById: (id: number, userId: number, otherUserId: number) => Promise<void>
  setIds: (newIds: Array<number>) => void
  setById: (newValue: { [id: number]: FeedbackModel }) => void
  setByTranslatedId: (newValue: { [id: number]: FeedbackModel }) => void
  setShowFilters: (newValue?: boolean) => void
  setUiState: (newUiState: UiState) => void
  setFeedbackItemStateById: (newState: { [id: number]: FeedbackItem }) => void
  setPagination: (newPagination: { currentPage: number; totalPages: number }) => void
  fetchFeedback: (userId: number, feedbackFilterType?: FeedbackFilterType) => void
  getEndReached: () => boolean
  resetCurrentPage: () => void
  getFeedbackById: (id: number) => FeedbackModel | undefined
  setScreenById: (id: number, screen: FeedbackScreen) => void
  getFeedbackItemStateById: (id: number) => FeedbackItem | undefined
}

export const initialValues: FeedbackContextType = {
  feedbackItemStateById: {},
  pagination: { currentPage: 0, totalPages: 0 },
  uiState: UiState.Idle,
  showFilters: undefined,
  ids: [],
  byId: {},
  byTranslatedId: {},
  editComment: () => new Promise(noop),
  updateFeedbackItemStateById: noop,
  initiateTranslation: () => new Promise(noop),
  deleteCommentByFeedbackId: () => new Promise(noop),
  deleteFeedbackById: () => new Promise(noop),
  moderateFeedbackById: () => new Promise(noop),
  setIds: noop,
  setById: noop,
  setShowFilters: noop,
  setUiState: noop,
  setFeedbackItemStateById: noop,
  setPagination: noop,
  fetchFeedback: noop,
  resetCurrentPage: noop,
  getEndReached: () => false,
  setByTranslatedId: noop,
  getFeedbackById: () => undefined,
  setScreenById: noop,
  getFeedbackItemStateById: () => undefined,
}

const FeedbackContext = createContext<FeedbackContextType>(initialValues)

export default FeedbackContext
