import classNames from 'classnames'

type Props = {
  progress: number
  icon: React.ReactNode
}

const BadgeProgress = ({ progress, icon }: Props) => {
  const style = { '--progress': progress * 100 } as React.CSSProperties

  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      className="profile-badges__progress"
      style={style}
    >
      <circle className="profile-badges__progress-bar__background" />
      {progress > 0 && <circle className="profile-badges__progress-bar__filling" />}
      <circle className="profile-badges__progress-small-background" />
      <foreignObject width="100%" height="100%">
        <div
          className={classNames('profile-badges__progress-icon', {
            'profile-badges__progress-icon--active': progress >= 1,
          })}
        >
          {icon}
        </div>
      </foreignObject>
    </svg>
  )
}

export default BadgeProgress
