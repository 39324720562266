'use client'

import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Spacer } from '@vinted/web-ui'
import classNames from 'classnames'

import { useFeatureSwitch } from '@marketplace-web/shared/feature-switches'
import SeparatedList from 'components/SeparatedList'
import ProfileBadges from 'pages/Profile/ProfileBadges'
import { ClosetPromotionCheckout } from 'components/ClosetPromotion'
import { exposeVasBannerLock } from 'data/api'
import { ProductItemModel } from 'types/models'

import useBreakpoint from 'hooks/useBreakpoint'
import useAbTest from 'hooks/useAbTest'

import { VasEntryPoint } from 'constants/vas-entry-point'

import * as statelessActions from 'state/banners/actions'
import * as selectors from 'state/banners/selectors'

import ReferralsBanner from '../ReferralsBanner'
import ClosetPromotionBanner from './ClosetPromotionBanner'
import BumpBanner from './BumpBanner'
import ProfileTaxpayerBanner from './ProfileTaxpayerBanner'
import useVasEntryPointsContext from './VasEntryPointsProvider/useVasEntryPointsContext'

type Props = {
  renderCountDownBanner: () => JSX.Element | null
  getSingleBumpableItem: () => ProductItemModel | undefined
}

const ProfileOwnerBanners = ({ renderCountDownBanner, getSingleBumpableItem }: Props) => {
  const dispatch = useDispatch()
  const breakpoints = useBreakpoint()
  const banners = useSelector(selectors.getBanners)
  const vasBannerLockAbTest = useAbTest({ abTestName: 'vas_banner_lock' })
  const isKillswitchBannersEnabled = useFeatureSwitch('killswitch_banners_web')

  const { vasEntryPoints } = useVasEntryPointsContext()

  const [isPrecheckoutOpen, setIsPrecheckoutOpen] = useState(false)

  function handlePrecheckoutOpen() {
    setIsPrecheckoutOpen(true)
  }

  function handlePrecheckoutClose() {
    setIsPrecheckoutOpen(false)
  }

  useEffect(() => {
    if (!vasBannerLockAbTest?.variant) return
    if (!vasEntryPoints) return

    exposeVasBannerLock()
  }, [vasBannerLockAbTest, vasEntryPoints])

  useEffect(() => {
    if (isKillswitchBannersEnabled) return

    dispatch(statelessActions.fetchBannersRequest())
  }, [dispatch, isKillswitchBannersEnabled])

  function renderSeparator() {
    return breakpoints.phones ? (
      <Spacer orientation={Spacer.Orientation.Horizontal} size={Spacer.Size.Medium} />
    ) : (
      <Spacer orientation={Spacer.Orientation.Vertical} size={Spacer.Size.XLarge} />
    )
  }

  const isPromotedClosetsCheckoutEntryPointAvailable = vasEntryPoints?.some(
    entryPoint => entryPoint.name === VasEntryPoint.ProfilePromotedClosets,
  )

  const promotedClosetsBanner = vasEntryPoints?.find(
    entryPoint =>
      entryPoint.name === VasEntryPoint.ProfilePromotedClosets ||
      entryPoint.name === VasEntryPoint.PromotedClosetsStats,
  )

  const bumpBanner = vasEntryPoints?.find(entryPoint => entryPoint.name === VasEntryPoint.PushUps)

  const classes = classNames({ 'u-flexbox': breakpoints.tabletsUp })

  return (
    <>
      <ProfileTaxpayerBanner taxpayerBanner={banners.taxpayerBanner} />
      <ReferralsBanner
        bottomSpacer={breakpoints.tabletsUp && <Spacer size={Spacer.Size.XLarge} />}
      />
      {renderCountDownBanner()}
      <div className={classes}>
        <SeparatedList separator={renderSeparator()}>
          {bumpBanner && (
            <BumpBanner bumpBanner={bumpBanner} getSingleBumpableItem={getSingleBumpableItem} />
          )}
          {promotedClosetsBanner && (
            <ClosetPromotionBanner
              promotedClosetsBanner={promotedClosetsBanner}
              handlePrecheckoutOpen={handlePrecheckoutOpen}
            />
          )}
        </SeparatedList>
      </div>
      <ProfileBadges />
      {isPromotedClosetsCheckoutEntryPointAvailable && (
        <ClosetPromotionCheckout
          isOpen={isPrecheckoutOpen}
          handlePrecheckoutClose={handlePrecheckoutClose}
        />
      )}
    </>
  )
}

export default ProfileOwnerBanners
