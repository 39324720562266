'use client'

import { ReactNode } from 'react'
import { Card, Cell, Text, Spacer, Badge, Image } from '@vinted/web-ui'
import { Lock16 } from '@vinted/monochrome-icons'

import { VasImages } from 'constants/vas-entry-point'

import useAsset from 'hooks/useAsset'

type Props = {
  title: ReactNode
  subtitle: ReactNode
  badgeTitle?: ReactNode
  badgeSubtitle?: ReactNode
  icon?: VasImages
  onClick: () => void
  testId?: string
  locked?: boolean
  lockedNote?: string
}

const VasBanner = ({
  title,
  subtitle,
  icon,
  onClick,
  testId,
  badgeTitle,
  badgeSubtitle,
  locked,
  lockedNote,
}: Props) => {
  const asset = useAsset('/assets/profile-banners')

  const getIconElement = () => {
    if (!icon) {
      return null
    }

    return (
      <Image
        src={asset(`${icon}.svg`, { theme: { dark: `${icon}-dark.svg` } })}
        size={Image.Size.XLarge}
        testId={icon}
      />
    )
  }

  const renderDiscountBadge = () => {
    if (!badgeTitle) return null

    return (
      <>
        <Spacer size={Spacer.Size.Medium} />
        <Cell
          styling={Cell.Styling.Tight}
          theme="transparent"
          prefix={<Badge content={badgeTitle} theme="primary" />}
          body={badgeSubtitle && <Text as="h4" type={Text.Type.Caption} text={badgeSubtitle} />}
        />
      </>
    )
  }

  const renderLockedBadge = () => {
    if (!locked) return null

    return (
      <>
        <Badge content={lockedNote} iconName={Lock16} testId="locked-badge" />
        <Spacer />
      </>
    )
  }

  return (
    <div className="u-fill-width u-fill-child-component">
      <Card experimentalTheme="primaryLightExperimental">
        <div className="u-overflow-hidden u-fill-height u-fill-child-component">
          <Cell type={Cell.Type.Navigating} theme="transparent" testId={testId} onClick={onClick}>
            {renderLockedBadge()}
            <Cell
              title={title}
              prefix={getIconElement()}
              theme="transparent"
              styling={Cell.Styling.Tight}
            >
              <Text as="h3" text={subtitle} type={Text.Type.Subtitle} html />
              {renderDiscountBadge()}
            </Cell>
          </Cell>
        </div>
      </Card>
    </div>
  )
}

export default VasBanner
