'use client'

import { AbTestVariant } from 'constants/abtest'
import useAbTest from 'hooks/useAbTest'

import LeavingVintedModal from './LeavingVintedModal'
import LeavingVintedModalVariantA from './LeavingVintedModalVariantA'
import LeavingVintedModalVariantB from './LeavingVintedModalVariantB'

type Props = {
  isOpen: boolean
  url: string | null
  onCancel: () => void
  onContinue: () => void
}

const LeavingModalExperiment = ({ isOpen, url, onCancel, onContinue }: Props) => {
  const leavingModalAbTest = useAbTest({ abTestName: 'external_link_warning' })

  if (leavingModalAbTest?.variant === AbTestVariant.A) {
    return (
      <LeavingVintedModalVariantA
        isOpen={isOpen}
        url={url}
        onCancel={onCancel}
        onContinue={onContinue}
      />
    )
  }

  if (leavingModalAbTest?.variant === AbTestVariant.B) {
    return (
      <LeavingVintedModalVariantB
        isOpen={isOpen}
        url={url}
        onCancel={onCancel}
        onContinue={onContinue}
      />
    )
  }

  return <LeavingVintedModal isOpen={isOpen} onCancel={onCancel} onContinue={onContinue} />
}

export default LeavingModalExperiment
